.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingBG {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1090;

  position: fixed;
  top: 0;
  left: 0;
  opacity: 0.5;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  transition: opacity 0.15s linear;
}

.loadingBG2 {
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1090;

  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
}

.loading {
  /*margin : 25% 0% 0% 50%  !important;*/
  z-index: 1100;
  padding: 5px 20px 5px 20px;
  /*ackground-color:red*/
}

.form-sm {
  margin: 0;
}

.no-button,
.no-button:hover {
  background: transparent !important;
  border: 1px solid transparent !important;
  box-shadow: none !important;
}

.form-control {
  border: 1px solid #ddd;
  box-shadow: none;
  color: #333;
  font-size: 15px;
  height: 40px;
}
.form-control:focus {
  border-color: #fe235b;
  box-shadow: none;
  outline: 0 none;
}
.form-control.form-control-sm {
  height: calc(1.5em + 0.5rem + 2px);
}

.form-control.form-control-lg {
  height: calc(1.5em + 1rem + 2px);
}

.card-label > label {
  background-color: #fff;
  color: #959595;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  margin: 6px auto auto 8px;
  padding: 0 7px;
}
.card-label > input {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 42px;
  margin-top: -13px;
  padding: 5px 15px 0;
  transition: border-color 0.3s;
  width: 100%;
}
.card-label > select {
  background-color: #fff;
  border: 1px solid #dbdbdb;
  border-radius: 4px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05);
  display: block;
  height: 42px;
  margin-top: -13px;
  padding: 5px 15px 0;
  transition: border-color 0.3s;
  width: 100%;
}

.card {
  border: 1px solid #f0f0f0;
  margin-bottom: 1.875rem;
}

.ant-table-thead > tr > th {
  font-size: 14px !important;
  font-weight: 600 !important;
}

.ant-table-tbody > tr > td,
.ant-table-thead > tr > th,
.ant-table tfoot > tr > td,
.ant-table tfoot > tr > th {
  padding: 3px !important;
}

.ant-table-tbody > tr > td {
  font-size: 13px !important;
}

.form-check {
  margin: 2px !important;
  font-size: 12px;
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
  overflow-wrap: normal !important;
}

table.table-sm th,
table.table-sm td {
  padding-top: 0.16rem;
  padding-bottom: 0.16rem;
}

input[type="radio"],
input[type="checkbox"] {
  opacity: 1 !important;
}

.transparent td {
  border: 0;
}

.no-spacing td {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.no-border {
  padding-right: 0px !important;
  margin-right: 0px !important;
  margin-left: 0px !important;
  padding-left: 10px;
}

.ant-table-tbody > tr > td {
  overflow-wrap: anywhere !important;
}

.flex-middle {
  display: flex;
  align-items: center;
}
